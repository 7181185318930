@for $i from 10 through 30 {
  .font-#{$i} {
    font-size: #{$i}px;
  }
  .font-#{$i}-f {
    font-size: #{$i}px !important;
  }
}

//text align
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-end {
  text-align: end;
}

//font family
.font-normal {
  font-family: Segoe UI;
}

.font-bold {
  font-family: Segoe UI Semibold;
}

.header-font {
font-size: 14px;
font-family: Segoe UI Semibold;
}

//font weight
.bolder {
  font: $font-info-text-bold;
  font-weight: bolder;
}

.text-normal {
  font-weight: normal;
}

.text-slim {
  font-weight: 400;
}

.text-extra-slim {
  font-weight: 100;
}

//text color
.text-highlight {
  color: $basic-button-color !important;
  label {
    color: $basic-button-color !important;
  }
}

.segoe-blue-text, .segoe-blue-text::placeholder {
  font-family: Segoe UI;
  color: $color-link !important;
}

//text size
.font-size-16 {
  font-size: 16px;
}

.font-size-16-f {
  font-size: 16px !important;
}

.no-decoration {
  text-decoration: none !important;
}
.no-decoration:hover {
  text-decoration: none !important;
}
