.statistics-box{
  width: 170px;
  height: 170px;
  background-color: #855cf8;
  margin-right: 30px;
  padding: 20px;
  color: white;
  font-weight: bolder;
  text-shadow: 3px 3px #6834fa; 
}

.statistics-box-value {
  font-size: 30px;
}
