//background
.transparent-background {
  background-color: inherit;
}

.bkg-white {
  background-color: white !important;
}
.bkg-F2F1F1 {
  background-color: #f2f1f1;
}
.bkg-F6F6F6 {
  background-color: #f6f6f6;
}
.bkg-F9F9F9 {
  background-color: #f9f9f9;
}
.bkg-content {
  background-color: $content-background-color;
}

//color
.color-danger {
  color: #dc3545 !important;
}

.color-white {
  color: white !important;
}

.color-yellow-highlight {
  background-color: #ffe59e;
}

.color-basic {
  color: $main-font-color;
}

.color-muted {
  color: $muted-font-color !important;
}

.color-link {
  color: $color-link;
}
