.sidebar-popover {
    display: none;
}

.popover-toggler:hover {
    .sidebar-popover {
        padding: 15px 0;
        display: block;
        position: absolute;
        background-color: white;
        width: 270px;
        left: $compressed-sidebar-width;
        box-shadow: 0px 1px 20px 0px #aeaeae;

        i,
        img {
            height: 1.5em;
            font-size: 1.5em;
            color: $basic-button-color !important;
            padding-left: 0 !important;
            margin-right: 5px;
        }

        .sidebar-btn {
            background-color: $basic-button-color;
            margin-top: 15px;
            width: calc(100% - 30px);
            margin: 0;
            color: white;

            i {
                color: white !important;
                height: 1em;
                font-size: 1em;
            }
        }

        .btn-link {
            margin-left: 0 !important;
            padding-left: 15px !important;

            i {
                height: 1em;
                font-size: 1em;
            }
        }

        .sidebar-label {
            padding: 0 15px;

            .fa,
            .fas {
                color: black !important;
            }
        }

        .sidebar-menu-item {
            font-size: 14px;
        }
    }
}
