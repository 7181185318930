.print {
  table{
    width:100%!important;
  }
 tr {
    border-bottom: 1px solid #ccc;
  }
 tbody tr:last-of-type {
    border-bottom: 0px solid #ccc;
  }
  th:first-of-type{
    width: 50px;
  }
  td{
    padding-bottom: 5px;
  }

  table,
  .info-bar,
  .info-bar>div,
  .print-attachments{
    margin-bottom: 1em;
  }

    table,
    .print-tags,
    .print-description,
    .print-attachments{
      margin-bottom: 2em;
    }

  .info-bar{
    display: inline;
  }
  .info-bar>div{
    width: 50%;
    display: inline-block;
  }
  .info-bar>div>label, .print-tags {
    width: 30%;
  }
  .print-tags>label, .print-attachments>label {
    display: block;
  }

}
