.link-item {
    @extend .flex-row, .full-width, .link;
    color: $main-font-color;
    display: flex;
    flex: auto;
    height: 35px;
    border: none !important;
    padding: 7px 15px;
    font-size: 14px;
}

.link-item:hover {
    color: $main-font-color;
}

.link-item.active {
    color: #0078d4 !important;
}

.link-item.active-highlight {
    color: #0078d4 !important;
    background-color: #deeaf3;
}

.link-item-inline {
    @extend .link;
    color: $main-font-color;
    height: 35px;
    border: none !important;
    padding: 7px 15px;
}

.link-item-inline:hover {
    color: $main-font-color;
}

.link-item-inline.active {
    color: #0078d4 !important;
}
