.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-visible {
  overflow-y: scroll;
  overflow-x: hidden;
}

.overflow-auto {
  overflow: auto;
}

.no-scrolling {
  textarea {
    overflow: hidden;
  }
  overflow: hidden;
}

//scroll X
.overflow-x {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-auto {
  overflow-x: auto !important;
}
