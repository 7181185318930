@function increment-by-5($i) {
    @return 0 + ($i * 5);
}
//HEIGHT
@for $i from 0 through 400 {
    .height-#{increment-by-5($i)} {
        height: #{increment-by-5($i)}px;
    }
}

.h-20-f {
    height: 20px !important;
}

.h-30 {
    height: 30px;
}

.h-30-f {
    height: 30px !important;
}

.h-60 {
    height: 60px;
}

.height-100 {
    height: 100px !important;
}

.h-100vh {
    height: 100vh;
}

.full-height {
    height: 100% !important;
}
@for $margin from 1 through 200 {
    .max-height-#{increment-by-5($margin)} {
        max-height: #{increment-by-5($margin)}px;
    }

    .min-height-#{increment-by-5($margin)} {
        min-height: #{increment-by-5($margin)}px;
    }

    .min-height-#{increment-by-5($margin)}-f {
        min-height: #{increment-by-5($margin)}px !important;
    }
}

.h-fit-content {
    height: fit-content !important;
}
//Fit with header

.fit-with-header {
    height: $height-without-header;
}

.fit-with-header-and-commandbar {
    height: calc(
        #{$height-without-header} - #{$commandbar-height}
    );
}

.fit-with-header-and-commandbar-columns {
    height: calc(
        #{$height-without-header} - #{$commandbar-height} -
            50px
    );
}

.fit-with-header-and-commandbar-list {
    height: calc(
        #{$height-without-header} - #{$task-list-commandbar-height} -
            12px
    );
}

.fit-with-header-and-commandbar-dnd {
    min-height: calc(
        #{$height-without-header} - #{$commandbar-height} -
            78px
    );
}

.fit-with-header-and-commandbar-calendar {
    height: calc(
        #{$height-without-header} - #{$commandbar-height} -
            60px
    );
}

.fit-with-header-and-commandbar-calendar-search {
    height: calc(
        #{$height-without-header} - #{$commandbar-height} -
            60px - 70px
    );
}

.fit-with-header-and-lanwiki-commandbar {
    height: calc(#{$height-without-header} - 40px);
}
//WIDTH

.full-width {
    width: 100% !important;
}

.w-a {
    width: auto;
}
//min-width
@for $i from 0 through 300 {
    .min-width-#{increment-by-5($i)} {
        min-width: #{increment-by-5($i)}px !important;
    }

    .max-width-#{increment-by-5($i)} {
        max-width: #{increment-by-5($i)}px !important;
    }
}
//pixels
@for $i from 0 through 400 {
    .width-#{increment-by-5($i)} {
        width: #{increment-by-5($i)}px !important;
    }
}
//percents
@for $i from 0 through 100 {
    .width-#{$i}-p {
        width: #{$i}#{'%'};
    }
}
