.ai-autofill-recommendation{
  background-color: #d3dfe8;
  border-radius: 10px;
  padding: 20px;
  color: #585858;
  margin: 10px;
  margin-bottom: 20px;
}

.ai-autofill-recommendation .fa{
  font-size: 30px;
  margin-top: 5px;
  margin-right: 15px;
  color: #585858;
}

.ai-autofill-recommendation label{
  color: #585858;
}

.ai-autofill-recommendation ol{
  padding-left: 1em;
}
