
.switchLabel {
  color: white;
  margin-left: 5px;
}

.switchLabel-right {
  color: white;
  margin-left: -20px;
}
