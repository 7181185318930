.page-header {
    background-color: $header-color !important;
    height: $header-height;
    margin: 0px;
    padding: 0px;
    border: 0px;
    border-right: 2px solid $header-color;
    //margin-right: -2px;

    .lansystems-title {
        display: flex !important;
        height: 100%;

        h1,
        i {
            color: #fff;
            font-size: 27px;
            margin-top: 2px;
        }
        i {
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 15px;
            cursor: pointer;
        }
    }
}

.header-link {
    color: white;
    margin-right: 10px;
    border: none !important;
    text-decoration: none;
    height: 40px;
    line-height: 40px;
    padding: 0px 10px !important;
}
.header-link:hover,
.header-link:focus {
    text-decoration: none;
    color: white;
}
.header-link-active {
    background-color: black;
}

.header-dropdown,
.header-dropdown:hover {
    background-color: transparent !important;
    border-width: 0px;
    padding: 0px;
    width: 30px;
}

.header-with-text,
.header-with-text:hover,
.header-with-text:focus {
    margin-right: 5px !important;
}

.header-icon,
.header-icon:active,
.header-icon:focus {
    cursor: pointer;
    font-size: 15px;
    background-color: transparent !important;
    border-width: 0;
    color: white;
    margin-right: 20px !important;
    display: flex;
    align-items: center;
}

.header-icon:last-of-type {
    margin-right: 10px !important;
}

.header-icon-text {
    font-size: 10px;
    margin-top: auto;
    color: #f2f1f1;
    align-self: flex-end;
}
