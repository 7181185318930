.settings-container {
    .search {
        width: 100% !important;
    }
}

.settings-container {
    .modal-content {
        background-color: $form-background-color;
        width: 1200px;
    }
}

.settings-add-container {
    width: 1200px !important;

    .modal-content {
        background-color: $form-background-color;
        padding: 0;

        .modal-body {
            padding: 0;
        }
    }
}

.settings-container {
    .table {
        @extend .row-highlight;
        margin-left: 30px;
        margin-right: 30px;
        width: calc(100% - 60px) !important; //0
    }
}

.settings-container,
.project-settings {
    .table {
        background-color: white;
    }

    .table > tbody > tr,
    .table > thead > tr {
        border-bottom: 1px solid $content-background-color;
    }

    .table > tbody > tr > td,
    .table > thead > tr > th {
        padding: 4px 10px !important;
        background-color: white;
        font-family: $main-font;
        color: #767676 !important;
        border: none;
        vertical-align: middle;
    }

    .table > thead > tr > th {
        font-size: 12px;
    }

    .table > tbody > tr > td {
        font-size: 14px;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 40px;
    }
}
