.modal-dialog {
  margin-top: 20px;
}

@media (min-width: 801px) {
  .modal-dialog {
    width: 1000px !important;
  }
}

@media (max-width: 800px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: inherit;
  }
}

.modal-header {
  justify-content: normal;
}

.modal-title {
  color: $main-font-color;
  font-size: 2.5rem;
  font-weight: 400;
  align-self: left;
}

.modal-without-borders {
  .modal-content {
    background-color: $content-background-color;
    padding: 0px;
    .modal-body {
      padding: 0px;
    }
  }
}

.modal.fade.show {
  opacity: 1;
}

.modal-content {
  background-color: $content-background-color; //white;
  padding-left: 20px;
  padding-right: 20px;
}

.small-modal {
  width: 600px !important;
  .modal-content {
    background-color: $content-background-color;
    padding: 0px;
    .modal-body {
      padding: 0px;
    }
  }
}

.statusChangeModal {
  .modal-content {
    padding: 20px;
    h1 {
      margin-bottom: 20px;
    }
    .react-datepicker-wrapper,
    .form-control {
      width: 100%;
    }
  }
}
