.popover > .arrow {
  display: none;
}

.custom-popover > .popover {
  background-color: $content-background-color;
  min-width: 200px;
  padding: 10px 15px;
  input {
    background-color: white;
  }
  input[type=radio]{
    //visibility: hidden;
    display: none;
  }
  .btn-link-reversed,
  .btn-link-reversed:hover,
  .btn-link-reversed:active,
  .btn-link-reversed:focus,
  .btn-link-reversed:active:focus {
    font: 14px Segoe UI;
    color: #a6a6a6;
    padding: 3.75px 7.5px;
    border: none;
    outline: none;
    background-color: #f2f2f2;
  }
}

.custom-popover-header{
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.custom-popover-item{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.custom-popover-item-double{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
