.clickable {
  cursor: pointer;
}

.grabbable {
  cursor: grab;
}

.not-allowed {
  cursor: not-allowed;
}

.normal-cursor {
  cursor: default;
}
