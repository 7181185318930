.comment > div > div.media {
    padding-top: 10px;
    display: flex;
}

.comment {
    background-color: #d5ebd4;
    padding: 0px 10px 10px 10px;
    border-radius: 10px;
}

.add-comment {
    padding: 15px 20px 20px 20px;
    border-radius: 10px;
}

.mail-to {
    background-color: #eaeaea;
    display: inline-flex;
    height: 30px;
}
