@font-face {
    font-family: Segoe UI Light;
    src: url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff);
}
@font-face {
    font-family: Segoe UI;
    src: url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff);
}
@font-face {
    font-family: Segoe UI Semibold;
    src: url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff);
}
@font-face {
    font-family: Segoe UI Bold;
    src: url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff);
}
@font-face {
    font-family: Gotham Book;
    src: local('Gotham Book'), url('./fonts/gotham/GothamBook.woff') format('woff');
}
