.btn,
.btn:active,
.btn:disabled,
.btn:focus,
.btn:focus:active,
.btn:focus:active,
.btn:hover {
    @extend .noselect;
    display: flex;
    align-items: center;
    background-color: $basic-button-color;
    color: #ffffff;
    border: 0px;
    height: $button-height;
    border-radius: 0px;
    font: $font-value;
    margin-left: 0px;
    margin-right: 0px;
    box-shadow: none;
    outline: none;
    opacity: 100;
    padding: 0 5px !important;
}

.btn:last-child {
    margin-right: 0;
}

.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
    @extend .noselect;
    display: flex;
    align-items: center;
    background-color: inherit;
    height: $button-height;
    border: 0;
    border-radius: 0;
    padding: 0;
    font: $font-value;
    color: $color-link;
    text-decoration: none;
    box-shadow: none;
    margin-left: 0;
    margin-right: 0;
    outline: none;
    cursor: pointer;
    width: fit-content;
    padding: 0;
}

.btn-link:disabled {
    display: flex;
    align-items: center;
    background-color: inherit;
    height: $button-height;
    border: 0;
    border-radius: 0;
    padding: 0;
    font: $font-value;
    color: $color-disabled;
    text-decoration: none;
    box-shadow: none;
    margin-left: 0;
    margin-right: 0;
    outline: none;
    cursor: not-allowed;
}

.btn-distance {
    margin-right: 10px !important;
}

.btn-link-reversed {
    @extend .noselect;
    display: flex;
    align-items: center;
    background-color: inherit;
    border: 0;
    border-radius: 0;
    color: $main-font-color;
    padding-left: 0;
    margin-left: 0;
}

.btn-link-reversed:hover {
    background-color: inherit;
    color: $main-font-color;
    text-decoration: none;
}

.btn-link-cancel,
.btn-link-cancel:active,
.btn-link-cancel:disabled,
.btn-link-cancel:focus,
.btn-link-cancel:focus:active,
.btn-link-cancel:hover {
    @extend .noselect;
    display: flex;
    align-items: center;
    background-color: inherit;
    font: $font-value;
    padding-left: 0;
    color: #a6a6a6;
    text-decoration: none;
    outline: none;
    border: none;
}

.btn-outline-danger {
    @extend .noselect;
    display: flex;
    align-items: center;
    color: #dc3545;
    border-color: #dc3545;
    background-color: white;
    border: 1px solid;
    border-radius: 0.25rem;
}

.btn-outline-danger:hover {
    background-color: #dc3545;
}

.btn-repeat,
.btn-repeat:active,
.btn-repeat:disabled,
.btn-repeat:focus,
.btn-repeat:focus:active,
.btn-repeat:focus:active,
.btn-repeat:hover {
    @extend .noselect;
    display: flex;
    align-items: center;
    background-color: white !important;
    font: $font-no-value;
    color: $color-no-value;
    text-align: left;
    outline: none;
    border: none;
    height: $button-height;
    padding-left: 8px;
}

.btn-repeat-white {
    @extend .noselect;
    display: flex;
    align-items: center;
    background-color: inherit !important;
    font: $font-no-value;
    color: $color-no-value;
    width: 100%;
    text-align: left;
}

.btn-repeat-white:hover {
    cursor: pointer;
    background-color: inherit !important;
    color: black;
}

.btn-outline-blue:not(:disabled):not(.disabled).active,
.btn-outline-blue:not(:disabled):not(.disabled):active,
.show > .btn-outline-blue.dropdown-toggle {
    color: #fff;
    background-color: $basic-button-color;
}

.btn-success {
    background-color: lawnGreen;
    border-radius: 30px;
    /*height: 30px;
  width: 30px;*/
}

.btn-link-red,
.btn-link-red:active,
.btn-link-red:active:focus,
.btn-link-red:focus,
.btn-link-red:hover {
    @extend .noselect;
    display: flex;
    align-items: center;
    color: red;
    background-color: transparent;
    outline: none;
    border: none;
    margin-right: 0;
    margin-left: 0;
    height: $button-height;
    border: 0;
    border-radius: 0;
    font: $font-value;
    box-shadow: none;
    opacity: 100;
}

.btn-red,
.btn-red:active,
.btn-red:active:focus,
.btn-red:focus,
.btn-red:hover {
    @extend .noselect;
    display: flex;
    align-items: center;
    background-color: white;
    color: red;
    border: 1px solid red;
    height: $button-height;
    border-radius: 0;
    font: $font-value;
    margin-left: 0;
    margin-right: 15px;
    box-shadow: none;
    outline: none;
    opacity: 100;
    padding: 0 10px !important;
}

.btn-full-red,
.btn-full-red:active,
.btn-full-red:active:focus,
.btn-full-red:focus,
.btn-full-red:hover {
    @extend .noselect;
    display: flex;
    align-items: center;
    background-color: red;
    color: white;
    border: 1px solid red;
    height: $button-height;
    border-radius: 0;
    font: $font-value;
    margin-left: 0;
    margin-right: 15px;
    box-shadow: none;
    outline: none;
    opacity: 100;
}

.btn-danger {
    background-color: red;
}

.btn-success:hover {
    background-color: lawnGreen;
}

.btn-danger:hover {
    background-color: red;
}

.btn-danger:disabled {
    background-color: $muted-font-color;
    color: white;
}

.btn-grey {
    background-color: lightGrey;
}

.form-buttons-row {
    display: flex !important;
    margin: 20px 0 0;
}

.hidden-button {
    color: transparent;
    background-color: inherit;
}

.hidden-button:hover {
    color: $basic-button-color;
    background-color: inherit;
}

.link {
    color: #007bff;
    text-decoration: none;
}

.link:hover {
    text-decoration: none;
    cursor: pointer;
}

.btn-secondary:disabled {
    background-color: $muted-font-color;
    color: white;
}

.waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    z-index: 1;
    will-change: opacity, transform;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.button-bar {
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f4f8fb;
    margin: 0;
    width: 100% !important;
    border-bottom: $main-border;
}
