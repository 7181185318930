.webchatBtn{
  position: absolute;
  right: 0px;
  bottom: 5%;
}

.webchatBtn label{
  display: block;
}

.webchatWindow{
  width: 100%;
}

.webchatWindow .row{
  flex-direction: row;
  flex-wrap: nowrap;
}

.webchatWindow .message{
  text-align: end;
  margin: 0px 20px;
}

.webchatWindow .comment{
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  word-break: break-word;
  word-wrap: normal;
  padding-right: 60px;
}

.webchatWindow .comment:last-of-type{
  margin-bottom: 0px;
}

.webchatWindow .comment label,
.webchatWindow .comment span{
  padding: 0px !important;
}

.webchatWindow .comment span{
  color: #838383;
}

.webchatWindow .comments{
  max-height: calc(100vh - 245px);
  padding: 0px 20px;
}

.webchatWindow .group{
  margin: 20px;
}

.webchatWindow .btn-row{
  margin: 0px 20px;
}

.webchatWindow .icon{
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 5px;
  display: flex;
  align-items: flex-start;
}

.webchatWindow .fa {
  font-size: 20px;
}
