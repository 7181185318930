.no-border {
  border: 0px;
}

.basic-border {
  border: $main-border !important;
}

.basic-border-top {
  border-top: $main-border;
}

.secondary-border {
  border: $secondary-border !important;
}
