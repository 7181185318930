p {
    margin-bottom: 0;
}

body {
    font-family: $main-font;
    background: $main-background-color;
    font-size: $main-font-size;
    color: $main-font-color;
}

html {
    overflow-y: hidden;
    font-size: 10px !important;
}

h1 {
    color: $main-font-color;
    font-size: 3.5rem;
}

h2 {
    color: $main-font-color;
    font-size: 2.5rem;
    font-family: Segoe UI;
}

h2.clickable:hover {
    color: $basic-button-color;
}

h3 {
    font-family: Segoe UI Semibold;
}

h4 {
    font-family: Segoe UI Semibold;
}
//Label

Label {
    font: $font-label;
    color: $color-label;
}

.label {
    border-radius: 0;
    background-color: $basic-button-color;
    font: $font-label;
    color: $color-label;
}

.label-event {
    background-color: inherit;
    color: rgb(155, 155, 155);
}

.label-info {
    font: $font-info-text;
    color: white;
    display: inline;
    padding: 0.1em 0.3em;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}

.attribute-label {
    color: $muted-font-color !important;
}

hr {
    margin-top: 0;
    margin-bottom: 0;
}

//chrome
.scroll-grey::-webkit-scrollbar {
    background: #eaeaea !important;
    //padding: 35px 1px 1px 1px;
    width: 14px;
}

.scroll-grey::-webkit-scrollbar-track {
    //background: #eaeaea60 !important; /* color of the tracking area */
}

.scroll-grey::-webkit-scrollbar-thumb {
    background-color: #c1c1c1 !important; /* color of the scroll thumb */
}

.scroll-grey::-webkit-scrollbar-button {
    background-size: 100%;
    height: 16px;
    width: 14px;
}

.scroll-grey::-webkit-scrollbar-button:vertical:start:decrement {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%235a6268'><polygon points='0,50 100,50 50,0'/></svg>");
    background-size: 8px !important;
    background-repeat: no-repeat;
    background-position: center;
}

.scroll-grey::-webkit-scrollbar-button:vertical:end:increment {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='%235a6268'><polygon points='0,0 14,0 7,7'/></svg>");
    background-size: 8px !important;
    background-repeat: no-repeat;
    background-position: center;
}

//firefox
.scroll-grey {
    scrollbar-color: #c1c1c1 #eaeaea !important;
}
