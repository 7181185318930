.icon-S {
    font-size: 1em;
}
.icon-M {
    font-size: 1.2em;
}
.hidden-icon {
    color: transparent;
    background-color: inherit;
}
.hidden-icon:hover {
    color: $basic-button-color;
}
.fa,
.fas,
.far {
    padding-right: 5px;
}

.thumb-sm {
    height: 32px;
    width: 32px;
}

.material-symbols-outlined {
    font-size: 19px;
}

.material-symbols-outlined.bigger {
    font-size: 24px;
}
