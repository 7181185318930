.list-commandbar{
  display: flex;
  padding: 0px 1px 0px 1px;
  margin-top: 17.5px;
  white-space: pre-wrap;
  background-color: $content-background-color; //#f4f8fb;
  border-bottom: $main-border;
}

.commandbar-addon {
  margin-top: 8.75px;
  margin-bottom: 8.75px;
}

.cmdb {
  .border {
    border: 1px solid #dddddd;
  }

  .sidebar {
    background-color: #f6f6f6;
    border-right: 1px solid $content-background-color;
    .sidebar-label {
      label {
        font: $font-info-text;
      }
    }
    hr {
      margin: 10px 15px;
    }
  }

  .row:before,
  .row:after {
    content: none;
  }

  .contents:not(.item-category-form) {
    max-width: 1300px;
  }

  .item-category-form {
    max-width: 850px;
  }

  .contents {
    padding: 0px $page-header-odsadenie $page-header-odsadenie
      $page-header-odsadenie;

    .item-creation-info {
      span:not(.bolder) {
        display: block;
        line-height: 2em;
      }
      span {
        font-size: 12px;
        text-align: right;
        font: $font-info-text;
        color: #cbcbcb;
      }
      .bolder {
        font: $font-info-text-bold;
        color: #5c5c5c;
      }
    }

    .item-attributes {
      margin-top: 20px;
      width: 60%;
      .entry {
        display: inline;
        width: 50%;
        height: 50px;
        margin-bottom: 10px;
        padding-right: 10px;
      }
      label {
        display: block;
        font: $main-font-size $main-font-bold;
      }
      .value {
        font: $main-font-size $main-font;
      }
      .value-input {
        input,
        textarea {
          width: 100%;
          height: $button-height;
        }
        input:focus,
        textarea:focus {
          border: 1px solid $basic-button-color;
        }
      }
    }

    .description {
      width: 70%;
      padding-right: 30px;
    }

    .description-yellow {
      width: 30%;
      background-color: rgba(255, 241, 0, 0.15);
      font: $main-font-size $main-font;
      line-height: 21px;
      color: #333333;
      padding: 1em;
      height: fit-content;
    }

    .table {
      margin: 20px 0px;
      background-color: #f7f7f7;
      tr {
        height: $button-height;
      }
      thead > tr,
      tbody > tr:not(:last-child) {
        border-bottom: 1px solid #e8ebee;
      }
      th,
      td {
        vertical-align: middle;
        border: none;
        padding-left: 0px;
      }
      th:first-child,
      td:first-child {
        padding-left: 10px;
      }
      th:first-child {
        font-family: $main-font-bold;
      }
      td {
        border: none;
      }
    }

    .nav-item {
      list-style-type: none !important;
      border: none;
      .nav-link {
        font: $font-value;
        color: #c4c4c4;
      }
      .nav-link:first-child {
        padding-left: 0px;
      }
      .nav-link.active {
        list-style-type: none;
        text-decoration: none;
        color: black;
        border: none !important;
        @extend Label;
      }
    }

    .comments {
      input {
        @extend .border;
      }
      input:focus {
        border: 1px solid $basic-button-color;
      }
    }
  }

  .edit {
    background-color: #f6f6f6;

    h1 {
      margin-top: 20px;
    }

    .title-edit {
      border: none;
      padding-left: 10px;
      margin-right: 10px;
      font-size: 28px;
      line-height: 37px;
      color: #333333;
      width: 90%;
    }
    .title-edit:focus,
    .item-category-title:focus {
      border: 1px solid $basic-button-color;
      outline: none;
    }

    .item-category-title {
      @extend .border;
      width: 60%;
    }

    .table {
      background-color: #f0f3f6;
      thead > tr > th {
        padding-left: $input-padding;
      }
      thead > tr > th:first-child {
        padding-left: calc(#{$input-padding} + 10px);
      }
      .btn-link.add-item {
        padding-left: calc(#{$input-padding} + 10px);
      }
    }
  }
}

.backupList textarea {
  resize: none;
}
